<template>
  <v-container fluid>
    <headerVue :title="$t('menu.editAssignedEquipment')" />

	<v-row v-if="validateForm">
		<v-col cols="12" style="text-align:center;">
			<v-alert type="error">Todos los campos son obligatorio</v-alert>
		</v-col>
	</v-row>
	<v-row v-if="validateNoSerie">
		<v-col cols="12" style="text-align:center;">
			<v-alert type="error">EL equipo con el Número de serie ingresado no existe</v-alert>
		</v-col>
	</v-row>

    <v-row>
      <v-col v-if="loading" cols="12" style="text-align:center;">
			<v-progress-circular
			:size="70"
			:width="7"
			color="purple"
			indeterminate></v-progress-circular>
		</v-col>
		<v-col cols="12" v-if="!loading">
        <v-card>
			<v-row>
				<v-col cols="1"></v-col><v-col cols="2">{{ $t("serieNumber") }}</v-col>
				<v-col cols="2">
					<v-text-field
					filled
					flat
					v-model="serieNumber"
					clearable
					@change="find()"
					append-icon="mdi-eye" />
				</v-col>
			</v-row>
			<v-row
            ><v-col cols="1"></v-col>
			<v-col cols="2"><v-text-field filled readonly v-bind:label="$t('brand')" v-model="brand" /></v-col>
            <v-col cols="2"><v-text-field filled readonly v-bind:label="$t('model')" v-model="modelo" /></v-col
            ><v-col cols="2"><v-text-field filled readonly v-bind:label="$t('equipmentType')" v-model="equipmentType" /></v-col></v-row
          ><v-row></v-row
          >
		  <v-row>
			<v-col cols="1"></v-col>
			<v-col cols="2">
				<v-text-field filled flat v-bind:label="$t('name')" v-model="name" />
			</v-col>
			<v-col cols="2">
				<v-text-field filled flat v-bind:label="$t('surname')" v-model="surname" />
			</v-col>
			<v-col cols="2">
				<v-text-field filled flat v-bind:label="$t('lastname')" v-model="lastname" />
			</v-col>
			<v-col cols="2">
				<v-text-field filled flat label="Puesto" v-model="job" />
			</v-col>
			<v-col cols="2">
				<v-text-field filled flat label="Área" v-model="area" />
			</v-col>
		  	</v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align-self="center"
        ><v-btn @click="back">{{ $t("back") }}</v-btn> <v-btn color="primary" @click="update">{{ $t("save") }} </v-btn></v-col
      >
    </v-row>
  </v-container>
</template>
<script>
import headerVue from "../../components/header.vue"
export default {
	components: {
		headerVue,
	},
	data() {
		return {
			serieNumber: "",
			brand: "",
			name: "",
			surname: "",
			lastname: "",
			job: "",
			area: "",
			validateForm:false,
			modelo: "",
			equipmentType: "",
			loading: false,
			validateForm:false,
			validateNoSerie: false,
			id:0,
		}
	},
	methods: {
		find() {
			this.loading = true;
			this.validateNoSerie = false;
			axios.get('https://consubanco.azurewebsites.net/api/CargaEquipoComputo/serie/'+this.serieNumber)
			.then(resp => {
				
				this.modelo = resp.data.modelo;
				this.equipmentType = resp.data.tipo;
				if( resp.data.marcas ){

					this.brand = resp.data.marcas.nombreDeMarca;
				}
				this.loading = false;
			})
			.catch(err => {
				
				this.validateNoSerie = true;
				this.loading = false;
				console.error(err);
			});
		},
		update() {
				
			this.validateForm = false;
			
			if( this.serieNumber && this.modelo && this.equipmentType &&
				this.name && this.surname && this.lastname
			){

				const headerCurrent = {
					'Content-type': 'application/json;',
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Credentials': 'true'
				}

				var raw = JSON.stringify({
					"id": this.id,
					"numeroDeSerie": this.serieNumber,
					"nombre": this.name,
					"apellidoPaterno": this.surname,
					"apellidoMaterno": this.lastname,
					"puesto": this.job,
					"area": this.area,
					"sucursal": ""
				});				
				
				axios.put('https://consubanco.azurewebsites.net/api/CargaEquipoComputoUsuarios/' + this.id, raw, {
					headers: headerCurrent
				})
				.then((response) => {
					
					this.$router.push( "asignaciones-equipo" );
				})
				.catch((error) => {

					console.warn( "save asignar-equipo => ", error);
				});
			}
			else{

				this.validateForm = true;
			}
		},
		back() {
			
			this.$router.push("/asignaciones-equipo");
		},
		inicialization() {
		this.jobs = jobsLoad()
		this.areas = areasLoad()
		},
	},
	created() {
		
		this.id = this.$route.params.id;
		
		axios.get( 'https://consubanco.azurewebsites.net/api/CargaEquipoComputoUsuarios/'+this.id )
		.then(resp => {

			this.name = resp.data.nombre;
			this.surname = resp.data.apellidoPaterno;
			this.lastname = resp.data.apellidoMaterno;
			this.job = resp.data.puesto;
			this.area = resp.data.area;

			this.serieNumber = resp.data.numeroDeSerie;
			this.find();
			this.loading = false;
		})
		.catch(err => {
			
			console.error(err);
		});
	},
}
</script>